.circle-icon {
  color: #000000;
  line-height: 0;
  transition: color .3s;

  @include hover {
    color: red;
  }

  &--without-hover {
    @include hover {
      color: #000000;
    }
  }

  &--red {
    color: red;

    @include hover {
      color: #000000;
    }
  }

  &--grey {
    color: #1A1A1A;

    @include hover {
      color: #000000;
    }
  }

  &--red-grey {
    color: red;

    @include hover {
      color: #1A1A1A;
    }
  }


  &--grey-red {
    color: #1A1A1A;

    @include hover {
      color: red;
    }
  }

  &--grey-yellow {
    color: 1A1A1A;

    @include hover {
      color: $yellow;
    }
  }
}
