.accordion {
  $self: &;
  margin-bottom: 25.6rem;
  position: relative;
  z-index: 100;

  @include mobile {
    margin-bottom: 16rem;
  }

  &__header {
    margin-bottom: 5.6rem;

    @include mobile {
      margin-bottom: 3.3rem;
    }
  }

  &__title {
    margin-bottom: .7rem;
    font-size: 3.8rem;
    @include yeseva;
    font-weight: 700;

    @include mobile {
      font-size: 2.4rem;
      margin-left: -.1rem;
      white-space: nowrap;
    }
  }

  &__subtitle {
    margin-bottom: 5rem;
    font-size: 1.4rem;

    @include mobile {
      margin-bottom: 0;
    }
  }

  &__title-module {
    margin-top: 8rem;
    @include yeseva;
    font-size: 2.3rem;
    line-height: 2.7rem;

    @include mobile {
      margin-top: 2rem;
    }
  }

  &__subtitle-module {
    font-size: 1.4rem;
    line-height: 2.8rem;
    font-weight: 400;

    @include mobile {
      width: auto;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__list-module {
    display: flex;
    margin: -.8rem;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__item-module {
    padding: .8rem;

    @include mobile {
      width: 100%;
    }

    &--biggest {
      width: 36.37%;

      @include mobile {
        width: 100%;
      }
    }

    &--big {
      width: 34.77%;

      @include mobile {
        width: 100%;
      }
    }

    &--small {
      width: 28.86%;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__link-subtitle {
    color: #0191FF;
  }

  &__icon-subtitle {
    margin-bottom: -.6rem;
    margin-left: .4rem;
    font-size: 2.1rem;
  }

  &__content {
    position: relative;
    display: none;

    #{$self}__item.active > & {
      display: block;
    }

    &--second {
      display: none;
      padding-top: 2.4rem;

      @include mobile {
        padding-top: 1.6rem;
      }

      #{$self}__item.active> &{
        display: block;
      }
    }
  }

  &__arrow {
    transition: transform .3s;
    transform: rotate(-90deg);
  }

  &__item {
    &.fixed {
      &>#{$self}__top>#{$self}__columns>#{$self}__column>#{$self}__arrow {
        transform: none;
      }
    }

    &--first {
      &.inactive {
        display: none;
      }
    }

    &--second {
      &.active {
        // background: #FFFBEF;
      }
    }

    &--fourth {
      transition: background .3s;

      @include hover {
        // background: #FFF4DA;
        background: #FFFBEF;
      }
    }
  }

  &__header-small {
    display: none;
    margin-bottom: .1rem;
    font-size: 1rem;
    line-height: 1.3rem;
    color: #7F7F7F;

    #{$self}__columns--second & {
      @include mobile {
        display: block;
      }
    }

    #{$self}__columns--fourth & {
      @include mobile {
        display: block;
      }
    }

    #{$self}__item.one-pick #{$self}__columns--second & {
      display: none;
    }
  }

  &__about-count {
    display: none;
  }

  &__list {
    &--first {
      margin: -6.3rem 0;

      @include mobile {
        margin: -4.8rem 0;
      }
    }

    &--second {
      margin: -2.9rem 0;

      @include mobile {
        margin: -.5rem 0;
        margin-left: 2rem;
      }
    }

    &--third {
      margin: -.4rem 0;
    }

    &--fourth {
      margin: -.5rem 0;
    }
  }

  &__item{
    &--first {
      padding: 6.3rem 0;

      @include mobile {
        padding: 4.8rem 0;
      }
    }

    &--second {
      border-bottom: 1px solid #D8D8D8;
      padding: 2.9rem 0;
      padding-left: 2rem;
      transition: padding .3s;

      @include mobile {
        padding: .5rem 0;
      }

      &.fixed {
        padding-bottom: .6rem;
      }
    }

    &--third {
      padding: .4rem 0;
      border-top: 1px solid #D8D8D8;
    }

    &--fourth {
      padding: .6rem 0;

      @include mobile {
        padding: .2rem 0;
        position: relative;
      }
    }
  }

  &__top {
    position: relative;

    &--first {
      margin-bottom: 2.9rem;
      padding-bottom: 2.4rem;
      padding-right: 4.2rem;
      border-bottom: 2px solid #000;

      @include mobile {
        padding-right: 0;
        padding-bottom: 1.2rem;
        margin-bottom: 1.4rem;
      }
    }

    &--second {
      @include hover {
        cursor: pointer;
      }
    }
  }

  &__columns {
    display: flex;

    &--first {
      align-items: flex-end;

      @include mobile {
        flex-wrap: wrap;
        align-items: flex-start
      }

      #{$self}__item.one-pick & #{$self}__column--name {
        flex: 1;
      }

      #{$self}__item.one-pick & #{$self}__column--about {
        flex: auto;
      }
    }

    &--second {
      align-items: flex-end;

      @include mobile {
        align-items: flex-start;
      }
    }

    &--fourth {
      align-items: center;

      @include mobile {
        flex-wrap: wrap;
      }
    }
  }

  &__popup-checkbox {
    position: absolute;
    top: 0%;
    right: -1rem;
    z-index: 10000;
    transform: translateY(-0%) translateX(100%);
    width: 20rem;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    background: #fff;
    pointer-events: none;
    box-shadow: 0 .2rem 2rem .1rem rgba(#646464, .5);
    opacity: 0;
    transition: opacity .3s;

    #{$self}__columns--second>#{$self}__column--checkbox &, #{$self}__columns--fourth>#{$self}__column--checkbox & {
      transform: translateY(-0%) translateX(-100%);
      right: auto;
      left: -1rem;
    }
  }

  &__column {

    #{$self}__columns--first &{
      &--checkbox {
        margin-left: .2rem;
        margin-right: 1.4rem;
        margin-bottom: -.1rem;
        align-self: center;

        @include mobile {
          margin-right: 1.3rem;
          margin-left: 0;
        }
      }

      &--name {
        width: 36rem;
        margin-right: 1rem;
        @include yeseva;

        @include mobile {
          width: calc(100% - 3.3rem);
          margin-right: 0;
          align-self: center;
        }
      }

      &--about {
        @include mobile {
          min-width: 100%;
          margin-top: 1rem;
        }
      }
    }

    #{$self}__columns--second & {
      &--name {
        width: auto;

        @include mobile {
          flex: 1;
          align-self: center;
        }
      }

      &>#{$self}__name {
        font-size: 2.1rem;
        font-weight: 600;

        @include mobile {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }

      &--about {
        @include mobile {
          flex: none;
        }
      }

      &--time {
        margin: 0;
      }

      &--cost {
        @include mobile {
          width: 6rem;
        }
      }

      &--checkbox {

        @include mobile {
          padding-top: 1.1rem;
          margin-left: 1rem;
        }
      }
    }

    #{$self}__columns--fourth & {
      &--name {
        @include mobile {
          width: 100%;
          margin: 0;
          margin-bottom: .2rem;
        }
      }

      &--about {
        @include mobile {
          width: 100%;
          margin: 0;
          flex: auto;
          margin-bottom: 0;
        }
      }

      &--cost {
        @include mobile {
          margin: 0;
          width: 6rem;
        }
      }

      &--time {
        @include mobile {
          margin: 0;
          margin-left: 0;
        }
      }

      &--checkbox {
        @include mobile {
          // margin-left: 1rem;
          // padding-top: 1.1rem;
          margin: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__example {
    #{$self}__columns--first & {
      font-size: 1.4rem;
      font-weight: 100;
      color: rgba(0,0,0,.5);
      line-height: 100%;

      @include mobile {
        display: none;
      }
    }
  }

  &__cost {
    #{$self}__columns--first & {
      font-size: 1.4rem;
      font-weight: 100;
      color: rgba(0,0,0,.5);

      @include mobile {
        display: none;
      }
    }

    #{$self}__columns--second & {
      @include mobile {
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }

    #{$self}__columns--fourth & {
      @include mobile {
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: left;
      }
    }

    #{$self}__item.one-pick #{$self}__columns--first & {
      display: none;
    }

    #{$self}__item.one-pick #{$self}__columns--second & {
      display: none;
    }
  }

  &__time {
    #{$self}__columns--first & {
      font-size: 1.4rem;
      font-weight: 100;
      color: rgba(0,0,0,.5);

      @include mobile {
        display: none;
      }
    }

    #{$self}__columns--second & {
      @include mobile {
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }

    #{$self}__columns--fourth & {
      @include mobile {
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: left;
      }
    }

    #{$self}__item.one-pick #{$self}__columns--first & {
      display: none;
    }

    #{$self}__item.one-pick #{$self}__columns--second & {
      display: none;
    }
  }

  &__about {
    #{$self}__columns--first & {
      font-size: 1.8rem;
      line-height: 100%;

      @include mobile {
        position: relative;
        padding-right: 6.8rem;
        font-size: 1.4rem;
      }
    }

    #{$self}__item.one-pick #{$self}__columns--first & {
      display: none;
    }
  }

  &__about-cost {
    #{$self}__columns--first & {
      @include mobile {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &__name {
    #{$self}__columns--first & {
      font-size: 2.7rem;
      line-height: 100%;
      font-weight: 700;
      line-height: 100%;

      @include mobile {
        font-size: 1.8rem;
      }
    }
  }

  &__main {
    margin-top: 5.7rem;
    margin-bottom: 3rem;
  }


  &__main>ul {
    // margin: -5rem 0;

    &>li.active>#{$self}__content>ul>li {
      // padding-bottom: .6rem;
    }

    &>li {
      // padding: 5rem 0;

      &>#{$self}__top {
        // margin-bottom: 2.9rem;
        // padding-bottom: 2.4rem;
        // padding-right: 4.2rem;
        // border-bottom: 2px solid #000;

        // @include mobile {
        //   padding-right: 0;
        //   padding-bottom: 1.2rem;
        //   margin-bottom: 1.4rem;
        // }

        &>#{$self}__columns {
          // align-items: flex-end;

          // @include mobile {
          //   flex-wrap: wrap;
          //   align-items: flex-start
          // }

          &>#{$self}__column {
            // &--checkbox {
            //   margin-left: .2rem;
            //   margin-right: 1.4rem;

            //   @include mobile {
            //     margin-right: 1.3rem;
            //     margin-left: 0;
            //   }
            // }

            // &--name {
            //   width: 29rem;
            //   margin-right: 1rem;
            //   @include yeseva;

            //   @include mobile {
            //     width: calc(100% - 3.3rem);
            //     margin-right: 0;
            //     margin-bottom: 1.5rem;
            //   }

            //   #{$self}__name {
            //     font-size: 2.7rem;
            //     line-height: 100%;
            //     font-weight: 700;
            //     line-height: 100%;

            //     @include mobile {
            //       font-size: 1.8rem;
            //     }
            //   }
            // }

            // &--about {
            //   @include mobile {
            //     min-width: 100%;
            //   }

            //   #{$self}__about {
            //     font-size: 1.8rem;
            //     line-height: 100%;

            //     @include mobile {
            //       position: relative;
            //       padding-right: 6.8rem;
            //       font-size: 1.4rem;
            //     }

            //     #{$self}__about-cost {
            //       @include mobile {
            //         position: absolute;
            //         top: 0;
            //         right: 0;
            //       }
            //     }
            //   }
            // }

            // #{$self}__cost, #{$self}__time {
            //   font-size: 1.4rem;
            //   font-weight: 100;
            //   color: rgba(0,0,0,.5);

            //   @include mobile {
            //     display: none;
            //   }
            // }
          }
        }
      }

      &>#{$self}__content>ul {
        // margin: -2.9rem 0;

        // @include mobile {
        //   margin: -1.4rem 0;
        //   margin-left: 2rem;
        // }

        &>li {
          // border-bottom: 1px solid #D8D8D8;
          // padding: 2.9rem 0;
          // padding-left: 2rem;

          // @include mobile {
          //   padding: 1.4rem 0;
          // }

          &>#{$self}__top {
            // @include hover {
            //   cursor: pointer;
            // }

            &>#{$self}__columns {
              // align-items: flex-end;

              &>#{$self}__column{

                // &--name {
                //   width: auto;
                //   flex: 1;
                // }

                // &>#{$self}__name {
                //   font-size: 2.1rem;
                //   font-weight: 700;

                //   @include mobile {
                //     font-size: 1.4rem;
                //     line-height: 1.8rem;
                //   }
                // }

                // &--about {
                //   @include mobile {
                //     flex: none;
                //   }
                // }

                // &--time {
                //   margin: 0;
                // }

                // &--cost {
                //   @include mobile {
                //     width: 6rem;
                //   }
                // }

                // &>#{$self}__time, #{$self}__cost {
                //   @include mobile {
                //     text-align: left;
                //     font-size: 1.4rem;
                //     line-height: 1.8rem;
                //   }
                // }

                // &>#{$self}__header-small {
                //   @include mobile {
                //     display: block;
                //   }
                // }

                // &--checkbox {
                //   @include mobile {
                //     padding-top: 1.1rem;
                //     margin-left: 1rem;
                //   }
                // }
              }
            }

          }


          &>#{$self}__content>ul {
            // margin: -.4rem 0;
            &>li {
              // padding: .4rem 0;
              // border-top: 1px solid #D8D8D8;

              &>#{$self}__top>ul {
                // margin: -.5rem 0;

                &>li {
                  // padding: .5rem 0;

                  &>ul {
                    // align-items: center;

                    @include mobile {
                      // flex-wrap: wrap;
                    }

                    &>#{$self}__column {

                      // &--name {
                      //   @include mobile {
                      //     width: 100%;
                      //     margin: 0;
                      //     margin-bottom: 1rem;
                      //   }
                      // }

                      // &--about {
                      //   @include mobile {
                      //     width: 100%;
                      //     margin: 0;
                      //     flex: auto;
                      //     margin-bottom: 1.4rem;
                      //   }
                      // }

                      // &--cost {
                      //   @include mobile {
                      //     margin: 0;
                      //     width: 6rem;
                      //   }

                      //   #{$self}__header-small {
                      //     @include mobile {
                      //       display: block;
                      //     }
                      //   }
                      // }

                      // &--time {
                      //   @include mobile {
                      //     margin: 0;
                      //     margin-left: auto;
                      //   }

                      //   #{$self}__header-small {
                      //     @include mobile {
                      //       display: block;
                      //     }
                      //   }
                      // }

                      // #{$self}__cost, #{$self}__time {
                      //   @include mobile {
                      //     font-size: 1.4rem;
                      //     line-height: 1.8rem;
                      //     text-align: left;
                      //   }
                      // }

                      // &--checkbox {
                      //   @include mobile {
                      //     margin-left: 1rem;
                      //     padding-top: 1.1rem;
                      //   }
                      // }
                    }
                  }
                }
              }
            }
          }

          &>#{$self}__content {
            // padding-top: 2.4rem;
          }
        }
      }
    }
  }

  &__column {
    &--name {
      width: 30.3rem;
      margin-right: 1rem;
    }

    &--about {
      flex: 1;
    }

    &--time {
      width: 4.5rem;
      margin-left: 2rem;

      @include mobile {
        width: 3.4rem;
      }
    }

    &--cost {
      width: 7.5rem;
      margin-left: 1rem;
    }

    &--checkbox {
      width: 2rem;
      margin-left: 2.2rem;
      position: relative;

      &.only-read {
        @include hover {
          #{$self}__popup-checkbox {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    &--arrow {
      position: absolute;
      left: -2rem;
      top: 50%;
      transform: translateY(-40%);
      font-size: 1.2rem;
      transition: transform .3s background-color .3s, border-color .3s;
      line-height: 0;

      @include mobile {
        top: 1rem;
        transform: none;
      }
    }

    &--example {
      @include mobile {
        display: none;
      }
    }
  }

  &__cost, &__time {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 100%;
    text-align: right;
  }

  &__about {
    font-size: 1.4rem;
    line-height: 1.8rem;

    @include mobile {
      margin-top: 0;
    }
  }

  &__name {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  &__example {
    position: relative;
    font-size: 0;
    cursor: pointer;

    #{$self}__columns--first & {
      cursor: default;
    }

    @include hover {

      #{$self}__eye {
        // color: #000;
      }

      #{$self}__popup {
        // opacity: 1;
        // pointer-events: auto;
      }
    }

    &.disabled {
      cursor: default;

      @include hover {
        #{$self}__popup {
          opacity: 0;
        }
      }
    }

    #{$self}__item.one-pick #{$self}__columns--first & {
      display: none;
    }
  }

  &__eye {
    font-size: 2.2rem;
    color: #FFE062;;
    transition: color .3s;

    #{$self}__example.disabled & {
      display: none;
    }
  }

  &__popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    position: absolute;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 2.2rem 10rem 3.5rem;
    background: #fff;
    box-shadow: 0 .2rem 5rem .1rem rgba(#646464, .5);
    opacity: 0;
    transition: opacity .3s;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -2rem;
      left: 50%;
      transform: translateX(-50%);
      border: 1rem solid transparent;
      border-top: 1rem solid #fff;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -100%;
      height: 1rem;
      width: 100%;
    }

    #{$self}__column.down & {
      bottom: auto;
      top: 4rem;
      z-index: 100000;

      &::after {
        bottom: auto;
        top: -2rem;
        border-top: 0;
        border: 1rem solid transparent;
        border-bottom: 1rem solid #fff;
      }
    }
  }

  &__img {
    width: 14.4rem;
    height: 27rem;
  }
}
