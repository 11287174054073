.popup-footer {
  &__wrapper {
    max-width: 80rem;
    width: 100%;
    display: flex;
    background: #fff;
    border-radius: 1rem;

    @include mobile {
      overflow-y: auto;
      max-width: 30rem;
    }
  }

  &__left {
    padding: 5rem 2rem 5.5rem 5.6rem;
    text-align-last: left;

    @include mobile {
      padding: 2.5rem 3rem;
    }
  }

  &__title {
    display: block;
    width: 40rem;
    margin-bottom: 2.4rem;
    @include yeseva;
    font-size: 3.8rem;
    line-height: 4.4rem;

    @include mobile {
      font-size: 2.4rem;
      line-height: 3rem;
      width: auto;
    }
  }

  &__subtitle {
    display: block;
    width: 31rem;
    margin-bottom: 13.5rem;
    font-size: 1.8rem;
    line-height: 2.6rem;

    @include mobile {
      font-size: 1.4rem;
      line-height: 1.6rem;
      width: auto;
      margin-bottom: 3rem;
    }
  }

  &__btn {
    width: 22.1rem;
    height: 5.6rem;
    color: #000;
    background: $yellow;
    border: 0;
    transition: color .3s, background-color .3s;
    
    span {
      font-size: 1.8rem;
      color: currentColor
    }

    @include hover {
      background: #000;
      color: $yellow;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: $yellow;
    font-size: 13.1rem;

    @include mobile {
      display: none;
    }
  }
}