.text-block {
  margin-bottom: 25.4rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  background: #F1F4F4;
  position: relative;
  min-height: 69.3rem;
  overflow: hidden;
  background: url(../img/content/text-block-bg.png) no-repeat center;
  background-size: cover;

  @include smallDesktop {
    margin-bottom: 10rem;
  }

  @include mobile {
    margin-bottom: 6rem;
    padding: 6.6rem 0 5.7rem;
    min-height: auto;
    background: #F1F4F4;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    @include mobile {
      position: static;
    }
  }

  &__text-padding {
    padding-left: 11.1rem;

    @include mobile {
      padding-left: 5.6rem;
    }
  }

  &__text {
    @include yeseva;
    font-size: 2.9rem;
    line-height: 3.7rem;
    color: $black;

    @include mobile {
      font-size: 1.9rem;
      line-height: 2.4rem;
    }
  }

  &__img {
    @include mobile {
      display: none;
    }
  }
}
