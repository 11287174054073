.portfolio {
  overflow: hidden;

  &__header {
    @include yeseva;
    font-size: 3.8rem;
    line-height: 4.4rem;
    color: $black;
    margin-bottom: 9.5rem;

    @include mobile {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 3.3rem;
    }
  }

  &__list {
    margin: -7.6rem;

    @include mobile {
      margin: -3.3rem;
    }
  }

  &__li {
    padding: 7.6rem;

    @include mobile {
      padding: 3.3rem;
    }
  }
}
