.add-file {
  $self: &;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: rgba($black, .6);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid $white;
  margin-top: 3rem;
  transition: border-color .3s;

  @include hover {
    border-color: $yellow;
  }

  &__input {
    display: none;
  }

  &__desc {
    font-size: 1.6rem;
    color: $white;
    font-weight: 500;
    transition: color .3s;
    margin-right: 3rem;

    #{$self}:hover & {
      @include fromDesktop {
        color: $yellow;
      }
    }
  }

  &__icon {
    font-size: 3rem;
    color: $white;
    stroke-width: inherit!important;
    transition: color .3s;

    #{$self}:hover & {
      @include fromDesktop {
        color: $yellow;
      }
    }
  }
}
