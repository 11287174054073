.container {
  width: 100%;
  max-width: 115.0rem;
  padding: 0 1.5rem;
  margin: 0 auto;

  @include mobile {
    padding: 0 2rem;
  }
}
