.virtues {
  margin-bottom: 18.2rem;
  position: relative;
  z-index: 100;

  @include mobile {
    margin-bottom: 6rem;
  }

  &__title {
    margin: 0 0 5.05rem;
    font-size: 3.8rem;
    line-height: 4.4rem;
    @include yeseva;
    text-align: left;

    @include mobile {
      margin-bottom: 3.3rem;
      font-size: 2.4rem;
      line-height: 2.8rem;
      align-items: left;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include mobile {
      margin: -1.65rem 0;
    }
  }

  &__item {
    width: 50%;
    padding-top: 5.7rem;
    padding-right: 6.7rem;
    border-top: 1px solid #D8D8D8;
    margin-bottom: 7rem;

    @include mobile {
      width: 100%;
      border: 0;
      padding: 1.65rem 0;
      margin: 0;
    }

    &:nth-child(2n) {
      border-left: 1px solid #D8D8D8;
      padding-left: 6rem;

      @include mobile {
        border: 0;
        padding-left: 0;
      }
    }

    &--short {
      width: 36%;

      @include mobile {
        width: 100%;
      }
    }

    &--long {
      width: 64%;

      @include mobile {
        width: 100%;
      }
    }

    &--shorter {
      width: 48.4%;

      @include mobile {
        width: 100%;
      }
    }

    &--longer {
      width: 51.6%;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__number {
    margin-right: 2.8rem;
    width: 6.8rem;
    height: 6.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    font-size: 2.3rem;
    @include yeseva;
    border: 2px solid #FFE062;
    border-radius: 50%;

    @include mobile {
      margin-right: 1.9rem;
      width: 4.7rem;
      height: 4.7rem;
      font-size: 1.8rem;
    }
  }

  &__name {
    font-size: 2.3rem;
    @include yeseva;
    font-weight: 700;
    flex: 1;

    @include mobile {
      font-size: 1.8rem;
    }
  }

  &__text{
    margin-top: 2.5rem;
    width: 100%;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #1D3649;

    @include mobile {
      margin-top: 1.1rem;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
}
