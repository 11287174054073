.accordion-counter {
  $self: &;
  position: sticky;
  left: 100%;
  bottom: 5rem;
  // transform: translateX(12rem);
  transform: translateX(-3.7rem);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17.8rem;
  padding: 2.5rem 0;
  line-height: 100%;
  text-align: center;
  border-radius: 20rem;
  background: #000;
  box-shadow: -3px 8px 31px 0px rgba(#37576F, .46);
  transition: background .3s;

  @include desktop {
    transform: none;
  }

  @include mobile {
    left: 100%;
    // transform: translateX(-50%);
    width: 28rem;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.2rem 4rem;
  }

  @include hover {
    background: $yellow;
  }

  &.active {
    background: $yellow;
  }

  &__choice {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: #fff;

    @include mobile {
      font-size: 1.4rem;
      margin-bottom: .5rem;
    }
  }

  &__cost {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: 500;
    color: #888888;

    @include mobile {
      margin-bottom: 0;
      font-size: 1.6rem;
    }
  }

  &__link {
    font-size: 1.4rem;
    font-weight: 500;
    color: #FFE062;
    transition: color .3s;

    #{$self}:hover & {
      @include fromDesktop {
        color: $black;
      }
    }

    #{$self}.active & {
      color: $black;
    }
  }
}
