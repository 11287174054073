.advantages {
  $self: &;
  color: $black;
  position: relative;
  z-index: 100;
  padding-top: 3.8rem;
  padding-bottom: 15.2rem;
  $listPadding: 2.8rem;
  overflow: hidden;
  background: #fff;

  @include smallDesktop {
    padding-bottom: 3rem;
    padding-top: 5rem;
  }

  @include mobile {
    $listPadding: 2.2rem;
    padding-bottom: 8rem;
    padding-top: 4.7rem;
  }


  &__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: -$listPadding 0;
    margin-bottom: 0;

    @include desktop {
      justify-content: center;
    }

    @include mobile {
      display: block;
      justify-content: flex-start;
    }

    &:before, &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: #d8d8d8;

      @include mobile {
        display: none;
      }
    }

    &:before {
      top: $listPadding;
    }

    &:after {
      bottom: 0;
    }
  }

  &__li {
    padding: $listPadding 0;
    position: relative;
    overflow: hidden;

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 2.2rem;
      height: 100%;
      bottom: 0rem;
      width: 100%;
      background: $black;
      transition: transform .3s;
      transform: translateX(-101%);
    }

    @include hover {
      &:before {
        transform: translateX(0);
      }
    }

    &:after {
      display: none;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #d8d8d8;

      @include mobile {
        display: block;
      }
    }

    &:last-child {
      &:after {
        @include mobile {
          display: none;
        }
      }
    }

    &--stretch {
      padding-right: 17.8rem;

      @include desktop {
        padding-right: 10rem;
      }

      @include smallDesktop {
        padding-right: 7.8rem;
      }

      &:before {
        transform: translateX(100%);
      }
    }

    &--price {
      padding-right: 20.4rem;

      @include desktop {
        padding-right: 10rem;
      }
    }

    &--garanty {
      flex: 1;
    }

    &--functions {
      padding-top: 4.8rem;
      padding-right: 32.4rem;
      margin-left: 18rem;
      padding-bottom: 0;

      @include desktop {
        padding-right: 20rem;
        margin-left: 10rem;
      }
    }

    &--deadline {
      padding-top: 4.8rem;
      flex: 1;
      padding-bottom: 0;
    }

    @include mobile {
      width: 100%;
      position: relative;
      margin: 0;
    }
  }

  &__card {
    border-left: 1px solid #d8d8d8;
    height: 100%;
    padding: 7.3rem 0 0 3.6rem;

    #{$self}__li:first-child & {
      border: none;
    }

    #{$self}__li--functions &, #{$self}__li--deadline & {
      padding-top: 0;
      padding-bottom: 7.9rem;

      @include mobile {
        padding: 0;
      }
    }

    @include mobile {
      border: none;
      padding: 0;
    }
  }

  &__img-box {
    height: 6rem;
    font-size: 6rem;
    display: inline-flex;
    align-items: center;
    margin-bottom: 4.2rem;
    color: #1D3649;

    @include mobile {
      margin-bottom: 2.6rem;
    }
  }

  &__img-icon {
    #{$self}__li--stretch & {
      width: 6rem;
      height: 6rem;
    }

    #{$self}__li--price & {
      width: 7.8rem;
      height: 5.4rem
    }

    #{$self}__li--garanty & {
      width: 7.4rem;
      height: 3.8rem;
    }

    #{$self}__li--functions & {
      width: 8.3rem;
      height: 5.3rem;
    }

    #{$self}__li--deadline & {
      width: 8.2rem;
      height: 5.3rem;
    }
  }

  &__title {
    @include yeseva;
    font-size: 2.3rem;
    line-height: 2.7rem;
    margin-bottom: 1.2rem;

    @include smallDesktop {
      font-size: 2.1rem;
      line-height: 2.5rem;
    }

    @include mobile {
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin-bottom: .8rem;
    }
  }

  &__desc {
     font-size: 1.4rem;
     line-height: 2rem;
     color: #1D3649;

     @include mobile {
       font-size: 1.2rem;
       line-height: 1.8rem;
     }

     br {
       @include mobile {
         display: none;
       }
     }
  }

  &__img-box, &__title {
    position: relative;
    z-index: 5;
    transition: color .3s;

    #{$self}__li:hover & {
      @include fromDesktop {
        color: $white;
      }
    }
  }

  &__desc {
    position: relative;
    z-index: 5;
    transition: color .3s;

    #{$self}__li:hover & {
      @include fromDesktop {
        color: #9299A2;
      }
    }
  }

  
}
