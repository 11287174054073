.carousel {
  $self: &;
  color: $black;

  &__container {

  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5rem;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  &__info-col {
    flex: 1;
    padding-right: 2rem;

    @include mobile {
      flex: auto;
      padding-right: 0;
    }
  }

  &__title {
    @include yeseva;
    font-size: 2.7rem;
    line-height: 3.1rem;
    margin-bottom: 1rem;

    @include mobile {
      font-size: 1.8rem;
      line-height: 2.1rem;
    }
  }

  &__title-desc {
    font-size: 1.8rem;
    line-height: 2.3rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  &__nav {
    line-height: 0;
    padding-top: .9rem;

    @include mobile {
      display: none;
    }

    &.hidden {
      display: none;
    }
  }

  &__nav-btn {
    line-height: 0;
    color: $black;
    transition: opacity .3s, color .3s, transform .3s;

    @include hover {
      color: $yellow;
    }

    @include fromDesktop {
      &:active {
        color: rgba($yellow, .6);
      }
    }

    &--prev {
      @include hover {
        transform: translateX(-0.5rem);
      }
    }

    &--next {
      margin-left: 5.2rem;

      @include hover {
        transform: translateX(0.5rem);
      }
    }

    &.slick-disabled {
      opacity: .3;
    }
  }

  &__nav-icon {
    width: 5.2rem!important;
    height: 2.7rem!important;
  }

  &__list {
    margin-left: -3rem;
    margin-top: -3rem;
  }

  &__li {
    margin-left: 4.5rem;

    @include mobile {
      margin-left: 2rem;
    }
  }

  &__item {
    padding-left: 3rem;
    padding-top: 3rem;
  }

  &__img-col {
    width: 15rem;
    height: auto;
    margin-bottom: 2.6rem;
    box-shadow: 0 0 20px 1px rgba($black, .3);
    transition: box-shadow .3s, transform .3s;
    border-radius: 1rem;

    @include mobile {
      width: 15rem;
      height: auto;
      margin-bottom: 1.7rem;
    }

    #{$self}__item:hover & {
      @include fromDesktop {
        box-shadow: 0 0 2rem 1px rgba($black, .4);
        transform: scale(1.03);
        cursor: pointer;
      }
    }
  }

  &__img {
    width: 100%;
    border-radius: 1rem;
  }

  &__desc {
    font-size: 1.3rem;
    transition: color .3s;

    @include mobile {
      font-size: 1.2rem;
    }
  }
}
