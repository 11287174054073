.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: rgba(#000, 0);
  pointer-events: none;
  opacity: 0;
  transition: background .3s;

  &.active {
    opacity: 1;
    pointer-events: auto;
    background: rgba(#000, .8);
  }
}
