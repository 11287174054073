.popup-photo {
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__wrapper-img {
    height: calc(100vh - 5rem);
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    overflow: hidden;
  }

  &__img {
  }

  &__text {
    text-align: center;
    color: #fff;
  }
}