.about {
  color: $black;
  position: relative;
  z-index: 100;
  margin-bottom: 12.5rem;
  margin-top: 5.2rem;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 13rem;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;

    @include mobile {
      display: none;
    }
  }

  @include mobile {
    padding-bottom: 0;
    margin: 0;
  }

  &__container {
    background: #FFE062;
    padding-top: 3rem;

    @include mobile {
      padding-top: 2rem;
    }
  }

  &__header {
    padding: 0 10rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2.3rem;

    @include mobile {
      padding: 0 2rem;
      margin-bottom: 3rem;
    }
  }

  &__logo {
    line-height: 0;
    margin-right: 2rem;
    color: $black;
    transition: color .3s;

    @include mobile {
      margin-right: 0;
    }

    @include hover {
      color: $white;
    }
  }

  &__logo-img {
    width: 15rem;
    height: 5.6rem;

    @include mobile {
      width: 8.1rem;
      height: 3rem;
    }
  }

  &__wrapper-right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__phone {
    font-size: 1.6rem;
    font-weight: 600;
    transition: color .3s;

    @include mobile {
      display: none;
    }

    @include hover {
      color: $white;
    }
  }

  &__btn {
    margin-left: 3rem;

    @include mobile {
      display: none;
    }

    @include hover {
      border-color: $white;

      .btn {
        &__text {
          color: $white;
        }
      }
    }
  }

  &__content {
    width: calc(100% - 16.6666vw);
    overflow: hidden;
    margin-left: auto;
    

    @include mobile {
      width: calc(100% - 2rem);
    }
  }

  &__animation-padding {
    padding: 10rem 0 13rem;

    @include mobile {
      padding: 0;
    }
  }

  &__animation {
    background: $black;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 2rem 1.5rem 9.5rem;

    @include mobile {
      margin-left: 2rem;
      padding: .8rem 2rem 2.5rem 1rem;
    }
  }

  &__left-col {
    width: 59.5rem;

    @include desktop {
      flex: 1;
    }
  }

  &__title {
    @include yeseva;
    font-size: 3.8rem;
    line-height: 4.7rem;
    margin-bottom: 4.2rem;
    max-width: 63rem;

    @include desktop {
      font-size: 3rem;
      line-height: 3.6rem;
      margin-bottom: 3rem;
    }

    @include mobile {
      font-size: 2rem;
      line-height: 2.3rem;
      margin-bottom: 2.9rem;
    }
  }

  &__title-small {
    font-size: 3.5rem;

    @include desktop {
      font-size: 2.7rem;
    }

    @include mobile {
      font-size: 1.7rem;
    }
  }

  &__order-now {
    width: 29.5rem;
    height: 6.5rem;
    font-size: 1.8rem;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 1px solid #979797;
    border-radius: 3px;
    margin-bottom: 1rem;
    transition: border-color .3s, color .3s;

    @include desktop {
      width: 20rem;
      height: 5.2rem;
      font-size: 1.4rem;
    }

    @include mobile {
      width: 100%;
      height: 5.2rem;
      margin-bottom: .5rem;
    }

    @include hover {
      color: $yellow;
      border-color: $yellow;
    }
  }

  &__btn-col {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include mobile {
      width: 100%;
    }
  }

  &__order-price {
    font-size: 1.8rem;
    color: rgba($white, .4);

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__phone-col {
    width: 50.5rem;
    height: 40.6rem;
    position: relative;
    margin-left: 5.3rem;
    margin-top: -2.5rem;
    margin-bottom: -9.5rem;

    @include smallDesktop {
      width: 32.7rem;
      margin-left: 4rem;
    }

    @include mobile {
      display: none;
    }
  }

  &__phone-img {
    position: absolute;
    max-width: none;
    max-height: none;

    &--big {
      width: 32.1rem;
      height: auto;
      z-index: 2;
      top: -8rem;

      @include smallDesktop {
        width: 22.5rem;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--small {
      width: 25.7rem;
      height: auto;
      left: 25.1rem;
      z-index: 1;
      top: -.2rem;

      @include smallDesktop {
        width: 18.2rem;
        height: auto;
        left: 15.4rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
