.arrow {
  position: fixed;
  bottom: 7rem;
  right: 9rem;
  z-index: 10;
  width: 9rem;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
  color: $yellow;
  background: #111111;
  border-radius: 50%;
  cursor: pointer;
  transition: color .3s, background-color .3s, opacity .3s;
  opacity: 0;
  pointer-events: none;

  @include hover {
    background: $yellow;
    color: #000;
  }

  @include mobile {
    display: none;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}