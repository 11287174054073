.slider {
  $self: &;

  margin-bottom: 2.4rem;

  @include mobile {
    display: none;
  }

  &__wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 18.4rem;

    @include desktop {
      width: 98rem;
      height: 16.1rem;
      margin: auto;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5.6rem;
    font-size: 2.5rem;
    line-height: 3.2rem;
    @include yeseva;
    font-weight: bold;
    color: #fff;

    @include desktop {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  &__cost {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5.4rem;
    display: block;
    padding: 1.4rem 3.3rem;
    @include yeseva;
    font-size: 3.8rem;
    line-height: 100%;
    color: #000;
    background: $yellow;
    border-radius: .5rem;
  }

  &__list-dot, .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0 -.75rem;
    margin-top: 3.4rem;
  }

  &__list-dot {
    display: none;
  }

  .slick-dots {
    &>li {
      padding: 0 .75rem;

      &>button {
        width: 1.1rem;
        height: 1.1rem;
        background: #D8D8D8;
        border-radius: 50%;
        transition: background-color .3s;
        font-size: 0;

        &:hover {
          background: #FFE062;
          cursor: pointer;
        }
      }

      &.slick-active>button {
        background: #FFE062;
      }
    }
  }

    &__item {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;

        &:first-child {
            opacity: 1;
            position: static;
        }

        &.slick-slide {
            opacity: 1;
            position: static;
        }
    }
}
