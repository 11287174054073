.why {
  background: #F1F4F4;
  padding: 6.1rem 0 8rem;
  color: $black;
  margin-bottom: 25.4rem;
  overflow: hidden;

  @include smallDesktop {
    margin-bottom: 10rem;
  }

  @include mobile {
    padding: 5rem 0 0;
    margin-bottom: 10.4rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include smallDesktop {
      flex-direction: column;
    }

    @include mobile {
      padding: 0;
    }
  }

  &__circle-col {
    width: 65.7rem;
    margin-left: -9.1rem;
    padding-right: 8rem;

    @include smallDesktop {
      order: 1;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    @include mobile {
      width: 32rem;
      height: 52rem;
      margin: 0 auto;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      padding-top: 7rem;
    }
  }

  &__circle {
    position: relative;
    background: #FFE062;
    width: 55rem;
    height: 55rem;
    border-radius: 50%;
    margin-right: 10.7rem;

    @include desktop {
      margin-left: 1.5rem;
    }

    @include mobile {
      width: 38.5rem;
      height: 38.5rem;
      margin: 0;
    }
  }

  &__img {
    position: absolute;
    left: 50%;
    transform: translateX(-67%);
    top: -9.3rem;
    max-width: none;
    max-height: none;

    @include mobile {
      position: relative;
      z-index: 1;
      left: -24rem;
      top: -3.8rem;
      transform: none;
      width: 45rem;
      pointer-events: none;
    }

    &--desktop {
      display: block;

      @include mobile {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include mobile {
        display: block;
        left: 50%;
        transform: translateX(-66%);
      }
    }
  }

  &__person {
    position: absolute;
    top: 10.3rem;
    right: -11.6rem;
    padding-left: 1.7rem;
    font-size: 1.3rem;
    line-height: 1.5rem;

    @include mobile {
      margin: 0;
      right: auto;
      left: 5.5rem;
      top: -6.9rem;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background: $black;
    }
  }

  &__name {
    font-weight: 500;
    @include yeseva;

    @include mobile {
      font-size: 1.3rem;
      @include yeseva;
    }
  }

  &__position {
    font-size: 1.2rem;
  }

  &__content {
    flex: 1;
    padding-left: 7.2rem;
    padding-top: 9rem;
    position: relative;
    z-index: 2;

    @include smallDesktop {
      padding-top: 0rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    @include mobile {
      order: 0;
      margin-bottom: 5.8rem;
      margin-left: auto;
      margin-right: auto;
      padding: 0 2rem;
    }
  }

  &__head {
    @include yeseva;
    font-size: 4.8rem;
    line-height: 5.5rem;
    margin-bottom: 2.2rem;

    @include mobile {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 1.9rem;
      width: 24rem;
    }
  }

  &__text {
    max-width: 44rem;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 2.7rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-bottom: 1.8rem;
    }
  }

  &__btn {
    margin-top: 5rem;
    background: #111;
    width: 20.8rem;
    height: 5.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: $white;
    transition: background .3s;

    @include smallDesktop {
      margin-top: 4rem;
    }

    @include mobile {
      margin-top: 3.1rem;
      width: 100%;
      font-size: 1.4rem;
    }

    @include hover {
      background: $yellow;
    }
  }
}
