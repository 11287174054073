.awards {
  color: $black;
  margin-bottom: 30rem;
  overflow: hidden;

  @include smallDesktop {
    margin-bottom: 10rem;
  }

  @include mobile {
    margin-bottom: 10.8rem;
  }

  &__header {
    @include yeseva;
    font-size: 3.8rem;
    line-height: 4.4rem;
    margin-bottom: 5rem;

    @include mobile {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 1.9rem;
    }
  }

  &__desc {
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 8.5rem;
    max-width: 86rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-bottom: 2rem;
    }
  }

  &__list-container {

  }

  &__list {
    font-size: 0;
    margin: -2.8rem;
    white-space: nowrap;

    @include desktop {
      white-space: normal;
      display: flex;
      flex-wrap: wrap;
    }

    @include mobile {
      margin: -2rem -1rem;
    }
  }

  &__li {
    padding: 2.8rem;
    display: inline-block;

    @include desktop {
      width: calc(100% / 3);
    }

    @include mobile {
      width: auto;
      padding: 2rem 1rem;
    }
  }

  &__img {
    width: 21.2rem;

    @include mobile {
      width: 100%;
      max-width: 13rem;
    }
  }
}
