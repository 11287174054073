.page-header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  $self: &;
  background: $black;
  height: 5.2rem;
  z-index: 999;
  box-shadow: 0 0 0px 0px rgba($black, .3);
  transition: box-shadow .3s, background .3s;

  &.fixed {
    box-shadow: 0 0 10px 1px rgba($black, .3);
  }

  &__grey {
    color: #BFBFBF;
  }

  @include mobile {
    display: none;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    max-width: 100%;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    @include smallDesktop {
      position: static;
        margin: -2.1rem -1rem;
        transform: none;
    }

    &--second {
      opacity: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-.5rem);
      top: 100%;
      flex-direction: column;
      width: max-content;
      margin: 0;
      padding: 1rem 0 1.3rem 0;
      background: #fff;
      transition: opacity .3s;
      pointer-events: none;
      border-radius: 0 0 .5rem .5rem;
      box-shadow: 0 .4rem 6.3rem 2rem rgba(34, 60, 80, 0.2);
      margin: -.2rem 0;

      &::after {
        content: '';
        top: -1.8rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        border: 1rem solid transparent;
        border-bottom: 1rem solid #fff;
      }

      #{$self}.fixed & {
        transform: translateX(-50%) translateY(-.5rem);
      }
    }

    &--third {
      opacity: 0;
      position: absolute;
      left: 100%;
      top: 0;
      flex-direction: column;
      margin: 0 -.5rem;
      padding: 1rem 2rem;
      background: #fff;
      transition: opacity .3s;
      pointer-events: none;
      margin: -.2rem 0;
    }
  }

  &__li {
    position: relative;
    padding: 2.1rem;

    @include smallDesktop {
      padding: 2.1rem 1rem;
    }


    #{$self}.fixed>ul>& {
      padding: 0;
      padding-left: 4.2rem;

      @include smallDesktop {
        padding: 2.1rem 1rem;

        &--second, &--third {
          padding: .5rem;
        }
      }

      &:first-child {
        padding-left: 0;

        @include smallDesktop {
          padding-left: 2.1rem;
        }
      }
    }

    &--second, &--third {
      padding: .8rem 2.6rem .8rem 1.3rem;
      transition: background-color .3s;

      @include hover {
        background: #F1F4F4;

        #{$self}__link {
          color: $yellow;
        }
      }
    }

    &:hover {
      &>ul {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__link {
    font-size: 1.5rem;
    color: $white;
    transition: color .3s;

    #{$self}.fixed & {

      @include hover {
        color: $yellow;
      }
    }

    @include hover {
      color: $yellow;
    }

    #{$self}__list--second & {
      color: #000;
      font-size: 1.5rem;
      line-height: 100%;
      font-weight: 600;
      display: block;
    }

    &__bold-text {
      font-weight: bold;
    }
  }

  &__logo {
    opacity: 0;
    transition: color .3s, opacity .3s;
    color: $white;
    line-height: 0;

    #{$self}.fixed & {
      opacity: 1;
    }

    @include hover {
      color: $yellow;
    }
  }

  &__logo-img {
      width: 5rem;
      height: 2rem;
  }

  &__contact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    opacity: 0;
    transition: opacity .3s;

    #{$self}.fixed & {
      opacity: 1;
    }
  }

  &__phone {
    font-size: 1.6rem;
    font-weight: 600;
    transition: color .3s;
    color: $white;

    @include hover {
      color: $yellow;
    }
  }

  &__btn {
    margin-left: 3rem;
    border-color: #fff;
    border-width: .1rem;

    .btn {
      &__text {
        color: #fff;
      }
    }
  }
}
