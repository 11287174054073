.btn {
  $self: &;
  width: auto;
  height: 3.9rem;
  border: 2px solid $black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
  transition: border-color .3s, background .3s;

  @include hover {
    border-color: $yellow;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: bold;
    color: $black;
    white-space: nowrap;
    transition: color .3s;

    #{$self}:hover & {
      @include fromDesktop {
        color: $yellow;
      }
    }
  }
}
