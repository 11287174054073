.checkbox {
  $self: &;
  position: relative;

  &.disabled {
    @include hover {
      cursor: default;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: -.5rem;
    top: -.5rem;
    display: block;
    width: 3rem;
    height: 3rem;
  }


  &__input {
    display: none;
    #{$self}.disabled & {
      @include hover {
        cursor: default;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
    color: #fff;
    background: #D8D8D8;
    border: 0;
    border-radius: .5rem;

    #{$self}__input:checked ~ & {
      background: #0191FF;

      #{$self}.disabled & {
        background: rgba(#0191FF, .16);
      }
      
      #{$self}__icon {
        opacity: 1;
      }
    }

  }

  &__icon {
    opacity: 0;
  }
}