.footer {
  $self: &;
  margin-top: 26rem;
  padding: 20.4rem 0 5.3rem;
  background: #000;
  overflow: hidden;

  @include mobile {
    padding: 6.8rem 0;
    margin-top: 12rem;
  }

  &__content {
    width: 70rem;
    padding-left: 1rem;
    margin: auto;
    padding-bottom: 23.4rem;

    @include mobile {
      padding-left: 0;
      width: 100%;
      padding-bottom: 4.6rem;
    }
  }

  &__title {
    margin-bottom: 2.3rem;
    font-size: 3.8rem;
    font-weight: 400;
    line-height: 100%;
    color: #fff;
    @include yeseva;

    @include mobile {
      font-size: 2.4rem;
    }
  }

  &__list-calc {
    display: flex;
    flex-wrap: wrap;
    margin: -.5rem -.5rem 2.9rem;

    &.hidden {
      display: none;
    }
  }

  &__item-calc {
    padding: .5rem;
  }

  &__wrapper-calc {
    padding: .8rem 1.6rem;
    font-size: 1.6rem;
    line-height: 100%;
    color: #000;
    background: $yellow;
    border-radius: .5rem;
  }

  &__subtitle {
    width: 42.5rem;
    margin-bottom: 6.2rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #fff;

    @include mobile {
      width: 30rem;
      margin-bottom: 4rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  &__list-input {
    margin: -1.1rem 0;
  }

  &__item-input {
    padding: 1.1rem 0;

    &.hidden {
      display: none;
    }
  }


  &__btn {
    margin-top: 4.8rem;
    min-width: 27rem;
    min-height: 7.3rem;
    background: #FFE062;

    span {
      font-size: 2.4rem;

      @include mobile {
        font-size: 1.8rem;
      }
    }

    @include mobile {
      margin-top: 3.6rem;
      width: 100%;
      min-height: 5.2rem;
    }

    @include hover {
      background: $black;

      .btn {
        &__text {
          color: $yellow;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 4.8rem;
    border-top: 1px solid #4A4A4A;

    @include mobile {
      border: 0;
      flex-direction: column;
    }
  }

  &__list-icons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.8rem;
  }

  &__item-icons {
    padding: 0 .8rem;
  }

  &__wrapper-icons {
    position: relative;
    font-size: 3rem;
    line-height: 0;

    &:hover {
      .icon-hover {
        fill: red!important;
        fill-opacity: 1!important;
      }
    }
  }

  &__circle-icon {
    transition: opacity .3s;

    &--hover {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }

    #{$self}__wrapper-icons:hover & {
      @include fromDesktop {
        opacity: 0;

        &--hover {
          opacity: 1;
        }
      }
    }
  }

  &__info {
    margin-top: 1.7rem;
    font-size: 1.4rem;
    @include helvetica;
    line-height: 100%;
    color: #4C4C4C;
  }

  &__copy-text {
      display: block;
      padding-bottom: 2rem;
  }

  &__mail {
    border-bottom: 1px solid #4C4C4C;;

      @include hover {
          color: #FFE062;
          border-color: #FFE062;
      }
  }

  &__right {
    @include mobile {
      margin-top: 2.7rem;
    }
  }

  &__call {
    display: flex;
    align-items: center;

    .circle-icon {
      color: #FFE062;
      font-size: 3rem;
      line-height: 0;
      margin-right: 1.4rem;
    }

    span {
      font-size: 1.6rem;
      letter-spacing: .11rem;
      color: #fff;
    }
  }

  &__go {
    position: relative;
    display: block;
    width: 22.5rem;
    min-height: 3.6rem;
    padding-left: 1.2rem;
    margin-top: 1.8rem;
    margin-right: 1.7rem;
    font-size: 1.4rem;
    @include helvetica;
    line-height: 3.6rem;
    background: #1A1A1A;
    color: #5E5E5E;
    border-radius: .3rem;

    &:hover {
      span {
        @include fromDesktop {
          color: $white;
        }
      }
    }

    span {
      color: #FFE062;
      margin-bottom: .1rem;
      transition: color .3s;
    }

    &:after {
      content: '';
      position: absolute;
      right: -3.5rem;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      border: 1.9rem solid transparent;
      border-left: 1.9rem solid #1A1A1A;
    }
  }
}
