.accordion-module {
  $self: &;

  padding: 1.5rem 2rem 2.1rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #E5E5E5;
  border-radius: .5rem;
  transition: background-color .3s;

  &.active {
    background: #F1F4F4;
  }

  &__title {
    margin-bottom: 1.7rem;
    @include yeseva;
    font-size: 1.8rem;
    line-height: 2.1rem;
  }

  &__text {
    display: block;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  &__top {
    margin-bottom: 1.8rem;
  }

  &__list {
    flex: 1;
    margin-bottom: .4rem;

    @include mobile {
      margin-bottom: 1.8rem;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__icon {
    margin-left: .5rem;
    margin-bottom: -.6rem;
    font-size: 2.1rem;
  }

  &__btn {
    min-width: 10.3rem;
    width: 10.3rem;
    height: 3.2rem;
    padding: 0 .6rem 0 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(#000, .5);
    border-radius: .5rem;
    transition: border-color .3s, background-color .3s;

    @include mobile {
      margin-top: 1.8rem;
    }

    span {
      font-size: 1.4rem;
      color: rgba(#000, .5);
      transition: color .3s;

      #{$self}.active & { 
        color: #fff;
      }
    }

    #{$self}.active & {
      background: #0191FF;
      border-color: #0191FF;

      @include hover {
        background: transparent;
        border-color: rgba(#000, .5);
  
        #{$self}__checked, span {
          color: rgba(#000, .5);
        }
      }
    }

    @include hover {
      background: $yellow;
      border-color: $yellow;

      #{$self}__checked, span {
        color: #fff;
      }
    }
  }

  &__checked {
    color: rgba(#000, .5);
    font-size: 1rem;
    transition: color .3s;

    #{$self}.active & { 
      color: #fff;
    }
  }
}