*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none!important;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  @include source;

  &.overflow {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &.bg-black {
    background: #000;
  }

  &.fixed {
    overflow: hidden;
  }
}

p {
  margin: 0;
}

address {
  font-style: normal;
}

button {
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
  color: currentColor;
}

img {
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: bottom;
}

label {
  cursor: pointer;
  user-select: none;
}

textarea {
  resize: vertical;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

input[placeholder] {
  text-overflow: ellipsis;
  white-space: nowrap;
}

:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus::-moz-placeholder {
  color: transparent;
}

:focus:-moz-placeholder {
  color: transparent;
}

:focus:-ms-input-placeholder {
  color: transparent;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
}

button,
input,
optgroup,
select,
textarea {
  @include source;
  box-shadow: none;
}

input {
  border: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

.row {
  display: flex;
}

.site-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  min-height: 100vh;

  &--visible {
    overflow-x: visible;
  }
}

.site-content {
  flex: 1 0 auto;

  &.hide {
    opacity: 0;
  }
}

.bg-cover {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-open {
  overflow: hidden;
}

.ie11 {
  .select {
    &__btn {
      min-width: 18rem;
    }
  }
}
