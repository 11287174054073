.input {
  $self: &;
  position: relative;
  display: block;
  width: 100%;
  height: 6.6rem;
  border-radius: .5rem;
  background: #1A1A1A;
  transition: background .3s;

  @include mobile {
    height: 5rem;
  }

  @include hover {
    background: rgba(#1A1A1A, .6);
  }

  &--textarea {
    height: 10rem;
  }

  &__placeholder {
    position: absolute;
    top: 2rem;
    left: 1.5rem;
    font-size: 1.6rem;
    color: #9299A2;
    pointer-events: none;
    transition: top .3s, font-size .3s, color .3s, font-weight .3s;

    #{$self}.active & {
      top: 1rem;
      font-size: 1.3rem;
      font-weight: 100;
      color: #646D78;

      @include mobile {
        top: .8rem;
        font-size: 1rem;
      }
    }

    @include mobile {
      top: 1.3rem;
      font-size: 1.4rem;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    background: 0;
    border: 0;
    padding-left: 1.7rem;
    padding-right: 1rem;
    padding-top: 1.3rem;
    font-size: 1.8rem;
    color: #fff;

    &--textarea {
      padding-top: 3.3rem;
      height: 10rem;
      resize: none;
    }
  }
}
